@import 'styles/custom-variables';

.opener {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  &:hover {
    svg {
      path {
        fill: $link-color;
      }
    }
  }
}
.dropDownMenu {
  font-size: 0.875rem;
}
