@import 'styles/custom-variables';
.wildcards {
  font-size: 0.675rem;
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  color: $gray-600;
  .wildcards-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    li {
      margin: 0 5px 5px;
      padding: 0 4px;
      background: $gray-200;
      border-radius: 3px;
    }
  }
}
