.fee-button-group {
  .btn {
    border-radius: 5px;
  }

  .btn-secondary {
    color: #939aa3;
    background-color: rgba(229, 233, 242, 0.4);
    border-color: rgba(229, 233, 242, 0.4);

    &:hover {
      background-color: rgba(229, 233, 242, 1);
      border-color: rgba(229, 233, 242, 1);
    }
  }
}
