.react-datepicker-wrapper {
  display: block;
}

.react-datepicker-popper {
  z-index: 7;
}

.react-datepicker__month-read-view--down-arrow {
  border-width: 0.2rem;
}
