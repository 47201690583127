.dot {
  background-color: #000000;
  width: 15px;
  height: 15px;
  margin: 2px;
  border-radius: 100%;
  display: inline-block;
  animation: animation-dot 0.75s 0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation-fill-mode: both;
}
.dot-2 {
  animation: animation-dot 0.75s 0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.dot-3 {
  animation: animation-dot 0.75s 0.36s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
@keyframes animation-dot {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
