@import '../../styles/custom-variables';
@import '../../styles/include-media';

.wrapper {
  background-color: #161616;
  height: 100%;
  color: $white;

  .anchor-wrapper {
    margin-top: 20px;

    @include media('>tablet') {
      margin-top: 64px;
    }
  }

  a {
    color: $gray-600;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
  @include media('>tablet') {
    color: $white;
    min-height: 100vh;
    background-image: url(../../assets/images/bg_login.jpg);
    background-position: 50% 50%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(65.52deg, #0e0e0e -3.33%, rgba(22, 22, 22, 0) 130.14%);
    }
  }

  p {
    font-weight: 500;
  }
}
.box {
  padding: 20px;
  position: relative;
  border: 0;
  z-index: 2;
  background-color: #161616;

  @include media('>tablet') {
    border-radius: $input-border-radius;
    padding: 110px;
  }
}
.box-body {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
}
.logo {
  margin-bottom: 20px;
  @include media('>tablet') {
    margin-bottom: 64px;
  }
}
