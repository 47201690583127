@import 'styles/custom-variables';
@import 'styles/include-media';
.user-nav {
  .userOrganization,
  .userEmail {
    color: $white;
    display: block;
  }
  .userEmail {
    color: $gray-600;
    font-size: 0.75rem;
  }
}
.userNavDropdown {
  .toggle {
    display: block;
    position: relative;
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }
  .dropdown-item {
    padding: 0.5rem 1.5rem;
    svg {
      display: inline-block;
      margin: -4px 8px 0 0;
      path,
      ellipse,
      circle {
        stroke: $gray-600;
      }
    }
    &:active,
    .active {
      background-color: $info;
      a {
        color: $white;
      }
      svg {
        path,
        ellipse,
        circle {
          stroke: $white;
        }
      }
    }
  }
  .has-link {
    padding: 0;
    a {
      text-decoration: none;
      display: block;
      padding: 0.5rem 1.5rem;
      color: $body-color;
      &.active {
        color: $white;
      }
    }
  }
  @include media('<tablet') {
    &.show {
      .dropdown-menu {
        position: static;
        float: none;
      }
    }
  }
}
