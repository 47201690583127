@import '../../../../styles/custom-variables';
:root {
  .copy-group {
    .form-control[readonly] {
      //background: $white;
      padding-right: 50px;
      background: $white;
      z-index: 1;
    }
    .btn-copy {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }
  .iframe-copy {
    textarea {
      min-height: 180px;
    }
    .btn-copy {
      top: auto;
      bottom: 0;
    }
  }
}
