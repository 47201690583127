@import '../../styles/custom-variables';

.dropzone-wrapper {
  height: 68px;
  width: 300px;
  border: 1px dashed #000 !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $cyan !important;
  font-weight: 500;
  outline: none !important;
  cursor: pointer;
  background-color: transparent !important;

  &.active {
    border-color: $cyan !important;
  }

  &.is-invalid {
    border-color: $red !important;
  }
}

.preview-container {
  height: 68px;
  width: 300px;
  font-size: 14px;
  font-weight: 500;

  img {
    max-height: 60px;
  }
}
