@import '../../styles/custom-variables';
.search-form {
  border-radius: $btn-border-radius;
  border: 1px solid $gray-300;
  .btn {
    min-width: 1px;
    border: none;
    background: none;
    color: $gray-900;
    &:hover,
    &:active {
      background: none;
      color: $cyan;
    }
    img,
    svg {
      width: 1.25rem;
      height: 1.25rem;
      vertical-align: middle;
    }
  }
  .form-control {
    border: none;
    border-radius: 50rem;
    font-size: 0.75rem;
    padding-left: 15px;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    -webkit-text-fill-color: #000;
  }
}
