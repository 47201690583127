@import '../../../styles/custom-variables';

.aside-nav.nav-pills {
  .nav-item {
    margin: 0 0 10px;
  }
  .nav-link {
    color: $dark;
    display: flex;
    align-items: center;
    padding: 1rem;
    font-weight: 500;
    .icon {
      width: 22px;
      margin-right: 17px;
    }
    svg {
      display: block;
      max-width: 100%;
      fill: $dark;
      path {
        fill: $dark;
      }

      &.icon-wrench {
        fill: none;
        stroke: $dark;
        path,
        ellipse {
          fill: none;
          stroke: $dark;
        }
      }
    }
    &.active {
      color: $cyan;
      background-color: #dae7ea;
      svg {
        fill: $cyan;
        path {
          fill: $cyan;
        }
        &.icon-wrench {
          fill: none;
          stroke: $cyan;
          path,
          ellipse {
            fill: none;
            stroke: $cyan;
          }
        }
      }
    }
    &.disabled {
      color: $gray-600;
      svg {
        fill: $gray-600;
        path {
          fill: $gray-600;
        }
      }
    }
  }
}
