@import '../../styles/custom-variables';
@import '../../styles/include-media';
.login-wrap {
  background-color: #161616;
  height: 100%;
  color: $white;
  a {
    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
  @include media('>tablet') {
    color: $white;
    min-height: 100vh;
    background-image: url(../../assets/images/bg_login.jpg);
    background-position: 50% 50%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(65.52deg, #0e0e0e -3.33%, rgba(22, 22, 22, 0) 130.14%);
    }
  }
}
.form-wrap {
  padding: 20px;
  position: relative;
  z-index: 2;
  @include media('>tablet') {
    background-color: #161616;
    border-radius: $input-border-radius;
    padding: 110px;
  }
}
.form-holder {
  max-width: 400px;
  margin: 0 auto;
  .form-control,
  .form-control:focus {
    color: $gray-900;
    background: $gray-300;
    border-color: $gray-300;
  }
  .input-group-text {
    background: $gray-300;
    border-color: $gray-300;
  }
  .form-group {
    margin-bottom: 1.5rem;
  }
  .btn {
    margin-top: 20px;
    @include media('>tablet') {
      margin-top: 64px;
    }
  }
  .form-label {
    text-transform: uppercase;
    color: $gray-900;
  }
}
.logo {
  margin-bottom: 20px;
  @include media('>tablet') {
    margin-bottom: 64px;
  }
}
