.autocomplete-wrapper {
  position: relative;
  height: auto;

  .autocomplete-icon-wrapper {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .autocomplete-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
}
