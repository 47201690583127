@import 'styles/custom-variables';
@import 'styles/include-media';

.filters {
  padding: 15px 0 5px;
  .invoice-info {
    border-radius: 8px;
    border-color: $gray-300;
    background-color: $dark;
    color: $white;
    padding: 7px 15px;
    margin: 0 0 10px;
    .title {
      display: block;
      text-transform: uppercase;
      color: $blue;
      font-size: 0.75rem;
    }
    .value {
      display: block;
      font-weight: 700;
      font-size: 1.5rem;
      &:first-letter {
        font-size: 0.75rem;
        line-height: 2.5;
        display: inline-block;
        margin-bottom: -5px;
        vertical-align: top;
      }
    }
    .pending {
      .title {
        color: #f79e1b;
        position: relative;
        &:before {
          display: inline-block;
          content: '';
          margin: 0 5px 0 0;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: #f79e1b;
        }
      }
    }
  }
  @include media('>=tablet') {
    .invoice-info {
      margin: 0 10px 10px 0;
      .info-item {
        margin-right: 3rem;
        &.pending {
          margin-right: 1.5rem;
        }
      }
    }
  }
}
