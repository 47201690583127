@import 'styles/custom-variables';
@import 'styles/include-media';
.notifications {
  .dropdown-menu {
    padding: 16px 10px;
    width: 544px;
    .heading {
      display: block;
      font-weight: 500;
    }
  }
  .notification-list {
    padding-top: 5px;
    margin-bottom: -15px;
    .notification-item {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      border: 1px solid $red;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 15px;
      white-space: normal;
      background-color: rgba(255, 77, 77, 0.05);
      .text-box {
        flex: 1 1 auto;
      }
      .title {
        font-size: 0.875rem;
        font-weight: 500;
        display: block;
      }
      .subtitle {
        font-size: 0.75rem;
        color: $gray-600;
      }
    }
    .icon-box {
      margin: 0 18px 0 0;
    }
    .btn {
      padding: 0 0.75rem;
      min-width: 100px;
    }
  }
  @include media('<992px') {
    .show.dropdown {
      .dropdown-menu {
        width: auto;
        position: static;
        text-align: center;
      }
      .notification-list {
        .notification-item {
          display: block;
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }
  }
}
