@import '../../../../styles/custom-variables';
.autocomplete-main {
  font-weight: 600;
  display: block;
  font-size: 0.875rem;
  color: #000;
}
.autocomplete-small {
  color: $gray-600;
  //display: inline;
  font-size: 0.75rem;
}
.highlightSearch {
  color: green;
}
.form-group .input-group {
  flex-wrap: nowrap;
}
