@import '../../../styles/custom-variables';

.emails-list-container {
  border-bottom: 1px solid $gray-200;

  .remove-email {
    display: none;
  }

  .input-group:hover {
    .remove-email {
      display: block;
    }
  }

  .input-group-text.is-invalid {
    border-color: $red;
  }
}
