@import '../../../styles/custom-variables';
@import '../../../styles/include-media';

.card.settings-banner {
  margin: 15px 0;
  background: linear-gradient(135deg, #0063cf 0%, #269b91 96.83%), #e5e9f2;
  color: $white;
  border-radius: 10px;
  .img-holder {
    padding: 5px 18px;
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto;
    }
  }
  .text-holder {
    .title {
      font-weight: 600;
      font-size: 1.75rem;
      margin: 0 0 5px;
    }
    .subtitle {
      display: block;
      font-weight: 600;
      font-size: 1.125rem;
      margin: 0 0 20px;
    }
    .list-unstyled {
      font-size: 0.875rem;
      font-weight: 600;
      li {
        position: relative;
        margin: 0 0 15px;
        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: $white;
          margin: 0 10px 2px 0;
        }
      }
    }
    .status {
      font-size: 0.875rem;
      font-weight: 600;
    }
    @include media('>=tablet') {
      padding: 25px 0;
    }
  }

  .btn-secondary {
    background-color: #fff;
    color: $cyan;
    display: inline-flex;
    align-items: center;

    svg {
      path {
        stroke: $cyan;
      }
    }
  }
}
.card.card-details {
  margin-bottom: 15px;
  border: 1px solid $gray-300;
  .card-body {
    display: flex;
  }
  .icon-holder {
    min-width: 25px;
    margin: 0 10px 0 0;
    svg {
      display: block;
    }
    &.alt {
      svg {
        path {
          fill: $cyan;
        }
      }
    }
  }
  .text-holder {
    font-size: 0.875rem;
    h3 {
      text-transform: uppercase;
      font-size: 0.875rem;
      color: $cyan;
      font-weight: 600;
      margin: 0 0 8px;
    }
  }
}
