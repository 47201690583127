@import 'styles/include-media';
@import 'styles/custom-variables';
.notification-templates {
  .card {
    height: 100%;
    border-color: $gray-500;
    .card-body {
      padding: 0.75rem;
    }
  }
  .template {
    margin-bottom: 15px;
    font-size: 0.75rem;
    align-items: stretch;
    .template-head {
      .card {
        min-height: 140px;
        align-items: center;
        flex-direction: row;
      }
      h3 {
        font-weight: 600;
        font-size: 0.75rem;
        margin-bottom: 4px;
      }
      .title {
        display: block;
        text-transform: uppercase;
        color: $gray-600;
        margin-bottom: 15px;
      }
      .btn {
        pointer-events: none;
        span {
          display: inline-block;
          vertical-align: middle;
          line-height: 1;
        }
        svg {
          display: inline-block;
          vertical-align: middle;
          path {
            stroke: $white;
          }
          color: #fff;
        }
      }
    }
    .template-body {
      h3 {
        text-transform: uppercase;
        color: $gray-600;
        font-weight: normal;
        font-size: 0.75rem;
      }
      .body {
        font-size: 0.875rem;
        color: $gray-800;
      }
      .card-footer {
        background: $white;
        border: none;
      }
    }
  }
  .template-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn:hover {
      opacity: 0.6;
    }
  }
  @include media('>=tablet') {
    .template {
      .template-head {
        max-width: 140px;
        margin-right: 5px;
      }
    }
  }
}
.templates-filter {
  font-size: 0.75rem;
  .title {
    text-transform: uppercase;
    font-weight: 500;
    margin: 0 10px 0 0;
  }
  .breadcrumb {
    padding: 0;
    margin: 2px 0 0;
    font-size: 0.75rem;
    line-height: 1;
    background: none;
    .link {
      font-size: 0.75rem;
      min-width: 1px;
      font-weight: 400;
      padding: 0;
      line-height: 1;
      color: $gray-600;
    }
    .active {
      .link {
        font-weight: 600;
        pointer-events: none;
        color: $primary;
        text-decoration: none;
      }
    }
  }
}
