@import '../../styles/custom-variables';
.btnClipboard {
  background: transparent;
  &:hover {
    color: red;
    svg {
      path,
      rect {
        stroke: $component-active-bg;
      }
    }
  }
}
