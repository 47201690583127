:root {
  .payment-form {
    .row .invalid-tooltip {
      padding: 0 15px;
    }
    .form-group {
      position: relative;
      .form-label {
        font-weight: normal;
        font-size: 0.65rem;
        text-transform: uppercase;
        margin: 0 0 5px;
      }
    }
  }
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  .StripeElement.form-control {
    width: 100%;
    padding: 12px;
    &.StripeElement--invalid {
      border-color: #ff4d4d;
    }
  }
  .card-subtitle {
    display: block;
    font-weight: 500;
    color: #939aa3;
    font-size: 0.65rem;
  }
}
