@import 'styles/include-media';

.logo-col {
  @include media('>=tablet') {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background: #f8f9fb;
    }
  }
}
.upload-logo {
  @include media('>=992px') {
    padding: 0 0 0 12%;
    min-height: 300px;
    border-left: 1px solid #dae1f0;
  }
}

[id='policy-tooltip'] {
  max-width: 280px;
  @include media('>=992px') {
    max-width: 560px;
  }
}
