.sortButtom,
.sortButtom:active,
.sortButtom:focus {
  background: none;
  border: none;
  outline: none;
  font-size: 0.75rem;
  line-height: 1.2;
  margin-top: -2px;
  .fa-sort {
    color: #939aa3;
  }
}
