@import 'styles/custom-variables';
.single-template {
  .title {
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
  }
  .how-send {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .col-box {
      max-width: 180px;
      width: 100%;
      margin: 0 5px 5px 0;
      &.small-col {
        max-width: 140px;
      }
    }
  }
}
.single-template-footer,
.single-template {
  .btn-preview {
    text-decoration: none;
    svg {
      path {
        transition: stroke 0.15s ease-in-out;
        fill: $info;
      }
    }
    &:hover {
      svg {
        path {
          fill: darken($info, 10%);
        }
      }
    }
  }
}
