.qrcode {
  max-width: 210px;
  border-radius: 8px;
  border: 1px dashed #939aa3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  &-holder {
    padding: 10px;
    background: #fff;

    canvas {
      display: block;
    }
  }
}
