@import '../../styles/custom-variables';
.not-found {
  text-align: center;
  background: $cyan;
  height: 100%;
  max-width: 100% !important;
  display: flex;
  align-items: center;
  .holder {
    width: 100%;
  }
}
.btn-back {
  color: $white;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: $white;
  }
}
.not-found-cloud {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 65px auto 30px auto;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 10px 5px -7px #666, 75px 1px 0 -3px #fff, 78px 4px 6px -5px #666,
    -77px 1px 0 -3px #fff, -80px 4px 6px -5px #666, -20px -50px 0 10px #fff,
    -21px -54px 6px 8px #666, 53px -50px 0 -17px #fff, 56px -53px 6px -20px #666;
  &::after {
    content: ('404');
    font-weight: 500;
    color: #444;
    font-size: 52px;
    letter-spacing: -3px;
    position: absolute;
    top: 0;
    right: 19px;
  }
}

.not-found-text {
  text-align: center;
  font-size: 1.5em;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 300;
}
