// Color system
$white: #fff !default;
$gray-100: #f8f9fb;
$gray-200: #e5e9f2;
$gray-300: #e8eef4;
$gray-400: #ced4da !default;
$gray-500: #eaeef2;
$gray-600: #939aa3;
$gray-700: #748aa1;
$gray-800: #6b6c7e;
$gray-900: #0e0e0e;

$blue: #46aaf7;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff4d4d;
$dark-red: #c8372d;
$orange: #fd7e14 !default;
$yellow: #f79e1b;
$green: #dae7ea;
$teal: #8dcfcf;
$cyan: #269b91;

$light: #e8eef4;
$dark: #161616;

$colors: () !default;
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: #f8f9fb;
$body-color: $gray-900;

$link-color: $cyan;

// Typography
$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';
$font-size-base: 1rem !default;
$font-size-lg: $font-size-base * 1.5;

// Navbar
$navbar-dark-color: #e8eef4;
$navbar-dark-hover-color: #fff;
$navbar-dark-active-color: $cyan;
$navbar-dark-brand-color: #fff;

$navbar-padding-y: $spacer;

// Cards
$card-border-color: #f5f7f9;

// Tables
$border-color: #e8eef4;
$table-th-font-weight: normal;

// Buttons + Forms
$btn-border-radius: 8px;
$input-btn-font-size: 0.875rem;
$input-btn-line-height: 2;

$input-color: $body-color;
$input-border-color: $gray-200;
$input-border-radius: 8px;
$input-disabled-bg: #f2f2f2;

$input-group-addon-bg: $white;
$input-group-addon-color: $gray-600;

$component-active-bg: $cyan;
$input-btn-focus-width: 0;
$input-focus-border-color: $gray-200;

//$custom-control-indicator-size: 1.1rem;
$custom-control-indicator-border-color: $gray-600;
$custom-control-indicator-border-width: 2px;
$custom-control-indicator-checked-bg: $white;
$custom-control-indicator-checked-border-color: $cyan;

// Modals
$modal-content-bg: $gray-100;
$modal-xl: 1200px;
$modal-lg: 860px;

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

//Breadcrumb
$breadcrumb-divider: quote('|');
