@import '../../../styles/custom-variables';
:root {
  .delivery-status {
    .box {
      min-width: 40px;
      min-height: 20px;
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-radius: 5px;
      margin: 0 3px;
      .error {
        display: none;
        width: 10px;
        height: 10px;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        margin: 0 3px;
        color: $white;
        background: $danger;
        border-radius: 100%;
      }
      &.delivered {
        background: rgba(38, 155, 145, 0.1);
        svg {
          path {
            stroke: $info;
          }
        }
      }
      &.failed {
        background: rgba(255, 77, 77, 0.1);
        svg {
          path {
            stroke: $danger;
          }
        }
        .error {
          display: block;
        }
      }
      &.pending {
        background: rgba(247, 158, 27, 0.1);
        svg {
          path {
            stroke: $warning;
          }
        }
      }
    }
  }
}
