@import '../../styles/custom-variables';
:root {
  .custom-date {
    margin: 15px 15px;
    border-top: 1px solid $gray-500;
    padding: 12px 0;
    h5 {
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 0;
    }
    label {
      font-size: 0.65rem;
      text-transform: uppercase;
      color: $gray-700;
      margin: 0 0 2px;
    }
    .form-control {
      font-size: 0.75rem;
      padding: 0.5rem 0.2rem;
      border-radius: 4px;
      text-align: center;
      line-height: 1.5;
      height: calc(2em + 0.2rem + 2px);
    }
    .btn {
      min-width: 100%;
      display: block;
      margin-top: 10px;
    }
  }
}
