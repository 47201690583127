@import 'styles/custom-variables';

.text-wysiwyg-editor {
  border-radius: 8px;
  border-color: $gray-200;
  &.has-error {
    border-color: $danger;
  }
  .editor-toolbar {
    margin: 0;
    border-width: 0 0 1px;
    border-radius: 8px 8px 0 0;
  }
  .editor-body {
    min-height: 150px;
  }
  .rdw-embedded-modal {
    overflow-y: auto;
  }
}
