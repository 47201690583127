@import 'styles/custom-variables';
@import 'styles/include-media';

.description {
  color: $gray-600;
  font-size: 0.75rem;
}
.pdfPreviewWrapper {
  overflow: hidden;
  .pdfPreviewHolder {
    max-height: 70vh;
    overflow-y: auto;
  }
  @include media('>=tablet') {
    position: relative;
    flex-grow: 1;
    .pdfPreviewHolder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-height: 100%;
    }
  }
}
