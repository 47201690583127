@import '../../styles/custom-variables';
:root {
  .steps {
    display: flex;
    font-size: 0.875rem;
    font-weight: 600;
    color: $gray-600;
    .step {
      position: relative;
      display: flex;
      align-items: center;
      .index {
        display: flex;
        align-items: center;
        justify-content: center;
        .text {
          display: block;
          width: 18px;
          height: 18px;
          font-size: 12px;
          line-height: 18px;
          border-radius: 100%;
          border: 1px solid $secondary;
          text-align: center;
        }
        .completed {
          display: none;
        }
      }
      .completed {
        svg {
          display: block;
          width: 18px;
          height: 18px;
        }
      }
      &.active {
        color: $primary;
        .index {
          .text {
            border-color: $primary;
          }
        }
      }
      &.completed {
        color: $primary;
        .index {
          border-color: $cyan;
          .completed {
            display: flex;
          }
          .text {
            display: none;
          }
        }
      }
    }
  }
}
