@import 'styles/custom-variables';
.btn-group-switcher {
  &.btn-group-sm {
    .btn {
      font-size: 0.65rem;
      min-width: 82px;
    }
  }
  .btn {
    svg,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    &[aria-disabled='true'],
    &.switched {
      pointer-events: none;
    }
    &.btn-light {
      color: $gray-600;
      svg {
        path {
          stroke: $gray-600;
        }
      }
    }
    &.btn-info {
      svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}
