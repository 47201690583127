:root {
  .btn {
    .icon-holder {
      width: 14px;
      margin: 2px 0 0 24px;
      display: inline-block;
      //vertical-align: middle;
      svg {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
