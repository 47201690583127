@import 'styles/custom-variables';
@import 'styles/include-media';
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#wrapper {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  z-index: 3;
  > .main-container {
    position: relative;
    flex-grow: 1;
    z-index: 2;
    .two-columns .container-fluid,
    > .container-fluid {
      max-width: 1440px;
    }
  }
}
#content {
  @include media('>=tablet') {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
#navigation {
  background-color: #212d42 !important;
}

.min-vh-50 {
  min-height: 50vh;
}

.text-decoration-underline {
  text-decoration: underline;
}
a.text-decoration-underline:hover {
  text-decoration: none;
}

//header
.header-nav {
  .navbar-nav {
    font-weight: 600;
  }
}
.navbar-brand {
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-style: italic;
  svg {
    margin-right: 12px;
  }
  span {
    color: $cyan;
  }
  @include media('>=desktop') {
    margin-right: 3rem;
  }
}

// table
.table-wrapper {
  flex-grow: 1;
  .table-responsive {
    @include media('>=tablet') {
      flex-grow: 1;
      overflow-x: hidden;
    }
  }
}
.table {
  th {
    border-top: none;
    text-transform: uppercase;
    color: $gray-600;
    font-size: 0.875rem;
  }
  thead th {
    border-bottom-width: 1px;
  }
}

// buttons
.btn {
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  min-width: 120px;
  @include media('>=desktop') {
    &.min-w {
      min-width: 187px;
    }
  }
}
.btn.text-transform-none {
  text-transform: none;
}
.btn-sm {
  font-size: 0.65rem;
  min-width: 73px;
}
.btn-icon {
  min-width: 1px;
  line-height: 1;
  font-size: 1.2rem;
  border-width: 2px;
  padding: 0 4px;
}
.btn-add {
  font-size: 1.5rem;
  line-height: 1;
  padding: 0;
  min-width: 1px;
  span {
    font-size: 0.875rem;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 4px 8px;
    text-decoration: none;
    color: $gray-900;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.btn-checkbox {
  border-radius: 5px;
  border: 1px solid #e5e9f2;
  background-color: $white;
  font-weight: 500;
  padding: 3px 10px;
  outline: none !important;
  box-shadow: none !important;
  &.selected {
    background-color: #b8d5d1;
    border-color: #269b91;
  }
}
.btn-secondary {
  color: $white;
}

.text-green {
  color: $cyan;
}
.text-teal {
  color: $teal;
}
.text-dark-red {
  color: $dark-red;
}
.text-light-gray {
  color: #b4b9bf;
}
.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.modal-dialog {
  @include media('<=desktop') {
    max-width: 100%;
    &.modal-lg,
    &.modal-xl {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  @include media('<tablet') {
    .modal-body {
      padding: 0;
    }
  }
}
.modal-header {
  background-color: #fff;
  align-items: center;
  .btn-cancel {
    text-transform: uppercase;
    color: $gray-600;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0;
    min-width: 1px;
    border: none;
  }
  .modal-title {
    font-weight: 600;
    font-size: 1rem;
    position: relative;
  }
}
.modal-footer {
  &.styled-footer {
    background-color: $green;
    @include media('>=desktop') {
      .btn {
        min-width: 106px;
      }
    }
  }
}

//forms
form {
  .title {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
  }
  .form-group {
    position: relative;
    .form-label {
      font-weight: 500;
      margin-bottom: 12px;
      font-size: 0.75rem;
      &.small-text {
        font-size: 0.65rem;
        font-weight: 500;
      }
      .content-wrap & {
        font-size: 0.875rem;
        margin-bottom: 5px;
        &.small-text {
          font-size: 0.65rem;
          font-weight: 500;
        }
      }
    }
  }
  .label-text {
    font-size: 0.75rem;
    display: block;
    color: $gray-600;
    margin: -10px 0 12px;
  }
}
.form-control {
  &.small-text {
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
    height: calc(2em + 1rem + 2px);
    &.StripeElement {
      padding: 13px 12px;
    }
  }
  &.part-width,
  .part-width & {
    max-width: 360px;
  }
}
.form-control.part-width {
  max-width: 360px;
}
textarea.form-control {
  line-height: 1.286;
  padding: 0.75rem;
  &.small-text {
    height: auto;
  }
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $gray-600;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23269b91ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.content-wrap .custom-file.form-label,
.custom-file {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  height: auto;
  margin: 0;
  font-weight: normal;
  overflow: hidden;
  .file-name {
    flex-grow: 1;
    overflow: hidden;
    padding: 0.5rem 0.75rem;
    height: calc(2em + 1rem + 2px);
    span {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
  .opener {
    height: calc(2em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    color: $white;
    background: $cyan;
    font-weight: 500;
  }
  input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.static-format,
.static-format:active,
.static-format:focus {
  border: none;
  padding: 0 5px;
  margin: 0;
  outline: none;
}

.total-holder {
  padding: 20px 0 0 0;
}
.total-note {
  font-size: 0.9rem;
  color: $gray-600;
  line-height: 1.6;
  margin: 5px 0 -5px 0;
  font-weight: 500;
}
.link {
  color: #269b91;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-weight: 500;
  border: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
}
.red {
  color: $red;
}
.input-group {
  .autocomplete-wrapper {
  }
  .form-control,
  select {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &.is-invalid {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  > .input-group-prepend {
    .input-group-text {
      border-right: 0;
      font-size: 0.75rem;
      &.is-invalid {
        border-color: #ff4d4d;
      }
    }
  }
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.invalid-tooltip {
  font-size: 0.65rem;
  padding: 0 0.25rem;
  background: none;
  color: #ff4d4d;
  margin: 0;
}

.fieldset-holder {
  border-bottom: 1px solid $gray-200;
  margin-bottom: 20px;
  div[class^='col'] & {
    &:last-child {
      border-bottom: none;
    }
  }
}
.fieldset-head {
  margin: 0 0 20px;
}
.fieldset-title {
  //color: $cyan;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
}
h3.fieldset-title {
  font-size: 0.75rem;
}
.label-subtitle {
  display: block;
  font-size: 0.875rem;
  color: $gray-600;
  margin-bottom: 6px;
}
.fieldset-subtitle {
  display: block;
  font-size: 0.875rem;
  color: $gray-600;
}

.tooltip {
  z-index: 1151 !important;
}

.card-header-tabs {
  font-size: 0.75rem;
}
.card-tab-content {
  .nav {
    margin: 0;
  }
  .tab-content {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    .tab-pane {
      width: 100%;
      &.active {
        display: flex;
        flex-grow: 1;
      }
    }
  }
}

.nav-tabs {
  font-size: 0.875rem;
  border-bottom: 1px solid $gray-500;
  position: relative;
  padding-top: 5px;
  .nav-link.active,
  .nav-item.show .nav-link,
  .nav-link.active:hover,
  .nav-link:focus,
  .nav-item.show .nav-link:hover {
    border-color: transparent transparent $cyan;
    background-color: transparent;
    color: $cyan;
    outline: none;
  }
  .nav-item {
    font-size: 16px;
    font-weight: 600;

    a {
      cursor: pointer;
    }
  }
  .nav-link {
    font-weight: 500;
    color: $body-color;
    padding-left: 0;
    padding-right: 0;
    padding-left: 5px;

    &:not(:first-child) {
      margin-left: 15px;
      padding-left: 0;
    }

    margin-right: 15px;
    margin-bottom: -1px;
    position: relative;
  }
  .nav-link:hover {
    border-color: transparent;
    color: $cyan;
  }
}

.preview {
  font-size: 0.75rem;
  color: $gray-600;
  .preview-pager {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    .btn {
      min-width: 1px;
      padding: 0 5px;
      line-height: 1;
      margin-top: 0;
    }
  }
}
.dzu-previewContainer {
  opacity: 0;
}
.total-preview {
  min-width: 150px;
  font-size: smaller;
}

// Filters panel
.filters-panel {
  background: $white;
  border-radius: 0.25rem;
}

//Pagination
.pager {
  .custom-select {
    font-size: 1rem;
    line-height: 1.25;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: auto;
  }
}

// Two-columns layout
.two-columns {
  .aside-column {
    background-color: $gray-300;
    padding: 25px 12px;
  }
  .content-wrap {
    padding: 20px 0;
  }
  .footer-container {
    background-color: $green;
    padding: 10px 15px;
    .btn {
      min-width: 104px;
    }
  }
  @include media('>=tablet') {
    min-height: 100%;
    display: flex;
    //height: calc(100vh - 78px);
    .content-wrap {
      padding: 20px 0 82px;
    }
    .aside-column {
      flex: 0 0 292px;
    }
    .content-column {
      flex-grow: 1;
      overflow: hidden;
      padding: 1rem 2rem;

      > .container-fluid {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .content-wrap {
      flex-grow: 1;
      position: relative;
      z-index: 1;
    }
    .footer-container {
      position: fixed;
      bottom: 0;
      left: 292px;
      right: 0;
      z-index: 2;
    }
  }
}

.react-datepicker__year-read-view--down-arrow {
  border-width: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

// modal-tabs
.modal-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tab.react-tabs__tab-panel--selected {
    flex: 1 1 auto;
  }
}

.alert-warning {
  color: $body-color;
  background-color: #fef5e8;
}

:root {
  // loading overlay
  ._loading_overlay_overlay {
    position: fixed !important;
    z-index: 9999 !important;
    ._loading_overlay_content {
      > span {
        display: block;
        margin: 0 auto;
        > span {
          background-color: $white;
        }
      }
    }
  }

  [id='policy-tooltip'] {
    max-width: 280px;
    @include media('>=tablet') {
      max-width: 560px;
    }
  }

  .react-pdf__Page__svg {
    width: 100% !important;
    height: auto !important;
    svg {
      width: 100% !important;
      height: auto !important;
    }
  }

  //modals
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5);
    //-webkit-perspective: 600;
    //perspective: 600;
    opacity: 0;
    transition: opacity 150ms ease-out;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content--before-close {
    -webkit-transform: scale(0.5) rotateX(30deg);
    transform: scale(0.5) rotateX(30deg);
    transition: all 150ms ease-in;
  }

  .ReactModal__Content.modal-dialog {
    outline: none;
  }
}
