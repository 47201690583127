@import '../../../styles/custom-variables';
:root {
  .payment-type-card {
    min-width: 210px;
    min-height: 115px;
    .card-footer {
      background: $white;
    }
    .link {
      font-size: 0.875rem;
    }
    .disabled {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
