@import '../../styles/custom-variables';
.payment-status {
  max-width: 340px;
  margin: 0 auto;
  padding: 15px;
  font-size: 0.875rem;
  .img-box {
    margin-bottom: 55px;
  }
  .title {
    display: block;
    text-transform: uppercase;
    color: $cyan;
    margin-bottom: 15px;
  }
}
